import { TimeSlotDto } from './timeSlot.dto';
import { PackageNameDto } from './packageName.dto';
import { Pricing, FeeType, PaymentType } from '../entities/enums';
import { AddonDto } from './addon.dto';
import { ResourceDto } from './resource.dto';

export class VenueDto {
  id: string;
  name: string;
  prefix: string;
  deposit: number;
  depositType: string;
  textBox: string;
  address: string;
  address2: string;
  city: string;
  zipCode: string;
  state: string;
  phone: string;
  lat: number;
  lng: number;
  dressCode: string;
  agePolicy: string;
  venueInfo: string;
  timeSlots: TimeSlotDto[];
  isStartTimeOnly: boolean;
  filterTimeSlots: number[];
  packages: PackageNameDto[];
  sandBoxPayment: boolean;
  paymentKey: string;
  stripePublishableKey: string;
  stripeAccountId: string;
  isStripeV2OtherMethodsEnable: boolean;
  stripeAdditionalMethods: string;
  enableCaptureStripePayment: boolean;
  fortisUrl: string;
  sezzlePublishableKey: string;
  sezzleUrl: string;
  timeZone: string;
  allowedReservationInterval: number;
  allowedReservationIntervalForAdmin?: number;
  ignoreStaffCapacityForAdmin: boolean;
  maxGuestsForInquiry: number;
  minGuestsForInquiry: number;
  mapUrl: string;
  locationUrl: string;
  closed: boolean;
  isComingSoon: boolean;
  enableCheckIn: boolean;
  pricing: Pricing;
  timeSlotDuration: number;
  maxGuests: number;
  maxGuestsForAdmin?: number;
  maxNumberOfLanes?: number;
  durationInSlotsForAdmin: string;
  assignDurationForAdmin: string;
  guestSplit: number;
  description: string;
  descriptionTitle: string;
  descriptionParty: string;
  partyUrl: string;
  closedMessage: string;
  inactivePackageMessage: string;
  showPolicy: boolean;
  allowCancellation: boolean;
  cancellationCutOff: number;
  cancellationFee: number;
  cancellationFeeType: FeeType;
  allowModification: boolean;
  modificationCutOff: number;
  modificationFee: number;
  modificationFeeType: FeeType;
  venueImage: string;
  secondaryVenueImages: string[];
  mapImage: string;
  daysForBooking: number;
  daysForBookingForAdmin?: number;
  durationInSlots: string;
  makePartyNoteText: string;
  reservationIsNotAvailableText: string;
  reservationDetails: string;
  timeSlotShifting: number[];
  lightScript: string;
  enableDayReport: boolean;
  enableCustomNotes: boolean;
  enableRequiredCustomNotes: boolean;
  customNotesTitle: string;
  customNotesPrefilledText: string;
  customNotesExplanationText: string;
  serviceFeeName: string;
  packagesTitle: string;
  packagesTabName: string;
  choosePackageTitle: string;
  ageForPolicy: string;
  eventInquiryTitle: string;
  eventInquiryTabName: string;
  eventConfirmationPageTitle: string;
  eventConfirmationPageCopy: string;
  eventEmailTitle: string;
  eventEmailCopy: string;
  packagesDescription: string;
  contactUsButtonText: string;
  serviceFee: string;
  preBufferTimeForReservation: number;
  bufferTimeForReservation: number;
  enableAssignDuration: boolean;
  assignDuration: string;
  shiftingForReservationStart: number;
  hasPackages: boolean;
  useDiscount: boolean;
  useGiftCard: boolean;
  serviceFeeType: string;
  addons: AddonDto[];
  addonsTitle: string;
  addonsDescription: string;
  resources: ResourceDto[];
  activeCustomFields?: string;
  mandatoryCustomFields?: string;
  packageTags: string[];
  defaultEmailForSkip: string;
  defaultPhoneForSkip: string;
  defaultFirstNameForSkip: string;
  defaultLastNameForSkip: string;
  skipGuestName: boolean;
  groupResourcesSideBySide: boolean;
  autoCompleteReservations: boolean;
  autoNoShowReservations: boolean;
  showSkipPaymentInHost: boolean;
  showPaymentLinkInHost: boolean;
  showRecordCashInHost: boolean;
  showGiftCardInHost: boolean;
  showManagersCompInHost: boolean;
  showManuallyEnterCardInHost: boolean;
  showCardReaderInHost: boolean;
  enableGoTabIntegration: boolean;
  paymentType: PaymentType | '';
  enableTripleSeats: boolean;
  enableSteltronic: boolean;
  calendarStartTime: number;
  calendarEndTime: number;
  calendarTimeWindow: number;
  calendarZoomLevel: number;
  enableReservationTimer: boolean;
  reservationTimerAlert: string;
  reservationTimerInterval: number;
  quickChatText: string;
  enableTwilio: boolean;
  enableTwilioPhoneVerification: boolean;
  twilioPhoneNumber: string;
  squareIsEnable: boolean;
  squareLocationName: string;
  squareLocationId: string;
  isSquareCashAppEnable: boolean;
  isSquareAfterPayEnable: boolean;
  faqTitle: string;
  faqs: string;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  tikTokUrl: string;
  sidebarTitle: string;
  sidebarDescription: string;
  sidebarLinks: string[];
  sidebarIcons: string[];
  sidebarUrls: string[];
  viewMenuUrl: string;
  customTabEnable: boolean;
  customTabTabName: string;
  customTabDescription: string;
  customTabScript: string;
  availability?: Availability;
  thankYouEmailEnable: boolean;
  thankYouEmailMinutes: number;
  thankYouEmailSubject: string;
  thankYouEmailHeader: string;
  thankYouEmailBody: string;
  eventInquiryEmailEnable: boolean;
  errorNotificationEmails: string;
  markAsPaidSourceOptions: string;
  stripeTerminalOptions: string;
  enableTwilioConfirmationText: boolean;
  enableTwilioReminderText: boolean;
  twilioConfirmationText: string;
  twilioReminderText: string;
}

export interface Availability {
  [day: string]: boolean;
}
